import React from 'react'
import { Navbar } from 'react-bootstrap'

class Footer extends React.Component {

  render() {

    return (
      <footer className="App-footer">
        <Navbar bg="dark" variant="dark">
          <h4 style={{fontSize: '2vw', marginLeft: 'auto', marginRight: 'auto', marginTop: '10px'}}>Have suggestions? Contact us at <a href="mailto:contact@realmetalprice.com">contact@realmetalprice.com</a></h4>
        </Navbar>
      </footer>
    )
  }
}

export default Footer
