import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'

import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import MultiPriceLineChart from 'src/components/common/charts/MultiPriceLineChart'

import styles from './styles.module.css'

const timePickerOptions = [
  {label: 'Minutely', value: 'prices'},
  {label: 'Hourly', value: 'prices_hourly'},
  {label: 'Daily', value: 'prices_daily'}
]

export default function ProductGroup() {
  const [productGroup, setProductGroup] = useState(undefined)
  const [productGroupPrices, setProductGroupPrices] = useState(undefined)
  const [priceKey, setPriceKey] = useState({label: 'Minutely', value: 'prices'})

    let { productGroupId } = useParams();

    useEffect(() => {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/readProductGroup?productGroupId=${productGroupId}`)
      .then(res => res.json())
      .then(json => {
        setProductGroup(json['productGroup'])
        setProductGroupPrices(json['productGroupPrices'])
      })
    },[productGroupId])

    if (!productGroup) return (<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '250px'}}><Spinner animation="border" role="status"></Spinner></div>)
    return (
      <>
        <Container className={styles.body} fluid>
          <h3><Link to={`/`}>Products</Link> &raquo; {productGroup.name}</h3>
          <div style={{height: '20px'}}></div>
          <h1>{productGroup.name}</h1>
          <Row>
            <Col xs={12} md={3} lg={3}>
            <Card>
              <Card.Img variant="rounded" src={productGroup.photo_url} style={{height: '200px', objectFit: 'contain'}}/>
              <Card.Body>
                <h3 style={{fontSize: '16px', marginBottom: '0px'}}>{productGroup.name}</h3>
                <h5 style={{fontSize: '16px', marginBottom: '0px'}}>{productGroup.year}</h5>
                <h5 style={{fontSize: '16px', marginBottom: '0px'}}>{productGroup.size}</h5>
                <div style={{height: '20px'}}></div>
                {productGroup.products.map((product, i) =>
                  <div key={i} style={{display: 'flex', alignItem: 'center', justifyContent: 'space-between', marginBottom: '10px'}}>
                    <div>{product.dealer}</div>
                    <a href={product.url} target="_blank" style={{fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline'}}>{product.available ? `$${product.last_price}` : 'SOLD OUT'}</a>
                  </div>
                )}
              </Card.Body>
            </Card>
            </Col>
            <Col xs={12} md={9} lg={9}>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', paddingTop: '20px', minHeight: '300px'}}>
                <h5>Price Times</h5>
                <Select
                  defaultValue={priceKey}
                  value={priceKey}
                  onChange={(value) => setPriceKey(value)}
                  label="Time Period"
                  options={timePickerOptions}
                  className={styles.timePicker}
                />
                <MultiPriceLineChart priceDataKey={priceKey.value} priceData={productGroupPrices[priceKey.value]} metal={productGroup.metal}/>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
