import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

var currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const colorMap = {
  'APMEX': "#8884d8",
  'JM Bullion': "#82ca9d",
  'SD Bullion': "#afca82"
}

export default class MultiPriceLineChart extends PureComponent {

  render() {
    const { priceData, priceDataKey } = this.props

    const sortedPrices = priceData.data.map(item => ({...item, datetime: new Date(item.datetime)})).sort((a, b) => a.datetime - b.datetime).map(item => {
      if (priceDataKey === 'prices') {
        return {...item, datetime: item.datetime.toLocaleTimeString()}
      } else if (priceDataKey === 'prices_hourly') {
        return {...item, datetime: item.datetime.toLocaleTimeString()}
      }
      return {...item, datetime: item.datetime.toLocaleDateString()}
    })

    const max = priceData.max * 1.01
    const min = priceData.min * 0.99

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={600}
          height={400}
          data={sortedPrices}
        >
          <XAxis dataKey="datetime" width={200} type="category"/>
          <YAxis type="number" domain={[min, max]} width={80} tickFormatter={(value) => currencyFormatter.format(value)}/>
          <Tooltip formatter={(value) => currencyFormatter.format(value)} />
          <Legend />
          {priceData.keys.map((series, i) => <Line key={i} type="monotone" dataKey={series} stroke={colorMap[series]}/>)}
        </LineChart>
      </ResponsiveContainer>
    );
  }
}
