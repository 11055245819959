import { Route, Routes } from 'react-router-dom'


import styles from './styles.css'

import Main from 'src/components/Main'

export default function App() {
  return (
    <div className={styles.body}>
      <Routes>
        <Route path="*" element={<Main/>} />
      </Routes>
    </div>
  )
}
