import { Route, Routes } from 'react-router'

import Footer from 'src/components/Main/Footer'
import NavBar from 'src/components/Main/NavBar'
import ProductGroup from 'src/components/Main/ProductGroup'
import ProductGroups from 'src/components/Main/ProductGroups'


export default function Main() {
  return (
    <>
      <Routes>
        <Route path="*" element={<NavBar/>} />
      </Routes>
      <Routes>
        <Route path="/products/:productGroupId" element={<ProductGroup/>}/>
        <Route path="*" element={<ProductGroups/>}/>
      </Routes>
      <Routes>
        <Route path="*" element={<Footer/>} />
      </Routes>
    </>
  )
}

