import { Component } from 'react'
import { Link } from 'react-router-dom'

import { Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap'

import styles from './styles.module.css'

class ProductGroups extends Component {
  state = {
    productGroups: undefined
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/listProductGroups`)
    .then(res => res.json())
    .then(json => this.setState({productGroups: json['productGroups']}))
  }

  render() {
    const {
      productGroups
    } = this.state

    return (
      <>
        <Container className={styles.body} fluid>
          {!productGroups &&
            <div style={{display: 'flex', alignItem: 'center', justifyContent: 'center', height: '250px'}}>
              <Spinner animation="border" role="status"></Spinner>
            </div>
          }
          <Row>
            {productGroups && productGroups.map((productGroup, i) =>
              <Col key={i} xs={12} md={6} lg={3} style={{marginTop: '20px'}}>
                <Card>
                  <Card.Img variant="rounded" src={productGroup.photo_url} style={{height: '200px', objectFit: 'contain'}}/>
                  <Card.Body>
                    <h3 style={{fontSize: '16px', marginBottom: '0px'}}>{productGroup.name}</h3>
                    <h5 style={{fontSize: '16px', marginBottom: '0px'}}>{productGroup.year}</h5>
                    <h5 style={{fontSize: '16px', marginBottom: '0px'}}>{productGroup.size}</h5>
                    <div style={{height: '20px'}}></div>
                    {productGroup.products.map((product, i) =>
                      <div key={i} style={{display: 'flex', alignItem: 'center', justifyContent: 'space-between', marginBottom: '10px'}}>
                        <div>{product.dealer}</div>
                        <a href={product.url} target="_blank" style={{fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline'}}>{product.available ? `$${product.last_price}` : 'SOLD OUT'}</a>
                      </div>
                    )}
                    <Link to={`/products/${productGroup.id}`}><Button variant="primary" style={{width: '100%', marginTop: '10px'}}>View Chart</Button></Link>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </>
    )
  }
}

export default ProductGroups
