import React from 'react'
import { Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class NavBar extends React.Component {
  state = {
    futures: undefined
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/listFutures`)
    .then(res => res.json())
    .then(json => this.setState({futures: json['futures']}))
  }

  render() {
    const {
      futures
    } = this.state

    return (
      <header className="App-header">
        <Navbar bg="dark" variant="dark">
            <Navbar.Brand style={{width: "100%"}}>
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                <Link to="/app">
                  <div style={{fontSize: '6vw'}}><span role="img" aria-label="logo">🪙</span></div>
                </Link>
                <div style={{width: '15px'}}></div>
                <div>
                  <h1 style={{fontSize: '3.5vw'}}>Real Metal Price</h1>
                  <h5 style={{fontSize: '2vw'}}>Track real Gold and Silver coin prices</h5>
                </div>
                {futures && <div style={{marginLeft: 'auto'}}>
                  <h3 style={{fontSize: '1.8vw', color: "#D4AF37"}}>{`Paper Gold: $${futures['gold'].last_price}`}</h3>
                  <h3 style={{fontSize: '1.8vw', color: "#aaa9ad", marginTop: '10px'}}>{`Paper Silver: $${futures['silver'].last_price}`}</h3>
                </div>}
              </div>
            </Navbar.Brand>

        </Navbar>
      </header>
    )
  }
}

export default NavBar
